<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("supplier.suppliers") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>
    <!-- action button -->
    <EcBox class="grid grid-cols-2 mt-2 lg:mt-4">
      <!-- Data filters -->
      <EcFlex class="justify-start">
        <RDataFilter
          ref="filterBoxRef"
          v-model="apiCriteria"
          :filterOptions="filterOptions"
          @appliedFilters="getSuppliers(true)"
        ></RDataFilter>
      </EcFlex>

      <!-- Buttons -->
      <EcFlex class="flex justify-end">
        <EcBox class="grid grid-cols-1 sm:grid-cols-2 gap-2 justify-end">
          <!--- export supplier -->
          <EcButton
            v-permissions:supplier-download
            :iconPrefix="exportSupplierIcon"
            class="mb-3 lg:mb-0"
            id="btn-export-supplier"
            variant="primary-sm"
            @click="handleClickDownloadSupplier"
          >
            {{ $t("supplier.buttons.exportSuppliers") }}
          </EcButton>

          <!-- Add supplier -->
          <EcButton
            v-permissions:supplier-create
            class="mb-3 lg:mb-0"
            id="btn-add-supplier"
            iconPrefix="plus-circle"
            variant="primary-sm"
            @click="handleClickAddSupplier"
          >
            {{ $t("supplier.buttons.addSupplier") }}
          </EcButton>
        </EcBox>
      </EcFlex>
    </EcBox>
    <!-- Table -->
    <EcTable
      ref="dataTableRef"
      name="tbl-supplier-list"
      :isLoading="isLoading"
      :columns="headerData"
      :dataSource="suppliers"
      :actionMenu="true"
      :rowCustomCss="rowCustomeCss"
      :rowClicked="handleClickRowItem"
      :apiCriteria="apiCriteria"
      @sorted="handleSort"
      @onEdit="handleClickEditSupplier"
      @onDelete="handleClickDeleteSupplier"
    >
      <template v-slot:status-name="{ item }">
        <EcFlex class="items-center w-full justify-center">
          <EcBadge :class="[item?.status?.tag_color, item?.status?.tag_text_color]" class="px-2 py-1 rounded-full min-w-12">
            {{ item?.status?.name }}
          </EcBadge>
        </EcFlex>
      </template>
    </EcTable>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (size) => {
            apiCriteria.pagination.size = size
          }
        "
      />
    </EcFlex>
  </RLayout>

  <!-- Modal Delete -->
  <teleport to="#layer1">
    <ModalConfirmDeleteSupplier
      :isModalDeleteOpen="isModalDeleteOpen"
      :supplier="supplierToDelete"
      @handleCallBackDeletedModal="handleCallBackDeletedModal"
      @handleCloseDeleteModal="handleCloseDeleteModal"
      @overlay-click="handleCloseDeleteModal"
    >
    </ModalConfirmDeleteSupplier>
  </teleport>

  <Teleport to="#layer2">
    <ModalActivityByDependency :selectedDependency="selectedDependency" dependable="supplier"></ModalActivityByDependency>
  </Teleport>
</template>
<script>
import { useSupplierList } from "@/modules/supplier/use/supplier/useSupplierList"
import { formatData, goto } from "@/modules/core/composables"
import ModalConfirmDeleteSupplier from "@/modules/supplier/components/ModalConfirmDeleteSupplier"
import ModalActivityByDependency from "@/modules/activity/components/ModalActivityByDependency.vue"

export default {
  name: "ViewSupplierApplication",
  data() {
    return {
      headerData: [
        {
          key: "name",
          title: this.$t("supplier.labels.supplierName"),
          sortable: true,
        },
        {
          key: "status.name",
          title: this.$t("supplier.labels.status"),
          sortable: true,
          sortKey: "status_id",
        },
        {
          key: "email",
          title: this.$t("supplier.labels.contactPoint"),
          sortable: true,
        },
        {
          key: "activities_count",
          title: this.$t("supplier.labels.linked_activities"),
          sortable: true,
          numeric: true,
        },
        {
          key: "shortest_mtpd_name",
          title: this.$t("supplier.labels.shortestMTPD"),
          numeric: true,
          sortable: true,
          sortKey: "shortest_mtpd",
        },
        {
          key: "created_at",
          title: this.$t("supplier.labels.createdAt"),
          sortable: true,
          date: true,
        },
      ],

      filterOptions: [
        {
          key: "name",
          title: this.$t("supplier.labels.supplierName"),
        },
        {
          key: "email",
          title: this.$t("supplier.labels.contactPoint"),
        },
        {
          key: "created_at",
          title: this.$t("supplier.labels.createdAt"),
          type: "date",
        },
      ],

      isDownloading: false,
      isModalDeleteOpen: false,
      supplierToDelete: {},
      selectedDependency: null,
    }
  },
  setup() {
    const { downloadSuppliers, globalStore, suppliers, isLoading, apiCriteria, getSuppliers, handleSort, t } = useSupplierList()

    return {
      downloadSuppliers,
      globalStore,
      suppliers,
      getSuppliers,
      handleSort,
      isLoading,
      apiCriteria,
      t,
    }
  },

  async mounted() {
    await this.apiCriteria.bindCriteria()

    this.$refs.filterBoxRef?.handleSetActiveConditions(this.apiCriteria.getSearchCriteria())
    this.$refs.dataTableRef?.setColumnActiveSort(...this.apiCriteria.getActiveSort())
    this.getSuppliers(true)
  },

  computed: {
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },

    exportSupplierIcon() {
      return this.isDownloading ? "Spinner" : "DocumentDownload"
    },
  },
  methods: {
    formatData,

    /**
     * Add new supplier
     */
    handleClickAddSupplier() {
      goto("ViewSupplierNew")
    },

    /**
     * Edit Supplier
     * @param {*} supplier
     */
    handleClickEditSupplier(supplier) {
      goto("ViewSupplierDetail", {
        params: {
          uid: supplier?.uid,
        },
      })
    },

    /**
     *
     * @param {*} supplier
     */
    handleClickDeleteSupplier(supplier) {
      this.supplierToDelete = supplier
      this.isModalDeleteOpen = true
    },

    /**
     * click to download supplier
     */
    async handleClickDownloadSupplier() {
      this.isDownloading = true
      await this.downloadSuppliers()
      this.isDownloading = false
    },

    /**
     * Open confirm delete modal
     */
    handleOpenDeleteModal() {
      this.isModalDeleteOpen = true
    },

    /**
     * Open cancel Add new Category modal
     */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },

    /**
     * Callback after add new category
     */
    handleCallBackDeletedModal() {
      this.getSuppliers(true)
      this.handleCloseDeleteModal()
    },

    /**
     * View supplier category list
     */
    handleClickViewCategories() {
      goto("ViewSupplierCategoryList")
    },

    /**
     *
     * @param {*} equipment
     */
    handleClickRowItem(item) {
      item.time = Date.now()
      this.selectedDependency = item
    },

    /**
     *
     * @param {*} item
     */
    rowCustomeCss(item) {
      return item?.activities_count > 0 ? "text-c1-800 hover:cursor-pointer" : ""
    },
  },

  components: { ModalConfirmDeleteSupplier, ModalActivityByDependency },
}
</script>
