import { ref } from "vue"
import * as api from "../../api/teamMemberFetcher"

export const useTeamMemberList = () => {
  const currentPage = ref(1)

  const getTeamMembers = async (param) => {
    try {
      const { data } = await api.fetchTeamMemberList(param)

      return data
    } catch (error) { }
  }

  return { currentPage, getTeamMembers }
}
