import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchTeamMember = async (uid) => {
  return fetcher.get(`identity/api/v1/team-members/${uid}`)
}

export const fetchTeamMemberList = async (params) => {
  return fetcher.get("identity/api/v1/team-members", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchTeamMemberUserList = async (params) => {
  return fetcher.get("identity/api/v1/team-members/users", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const createTeamMember = async (payload) => {
  return fetcher.post("identity/api/v1/team-members", payload)
}

export const updateTeamMember = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/team-members/${uid}`, payload)
}

export const deleteTeamMember = async (uid) => {
  return fetcher.delete(`identity/api/v1/team-members/${uid}`)
}
