<template>
  <EcBox class="overflow-x-auto lg:overflow-visible mt-8 lg:mt-8 p-2">
    <!-- Header -->
    <EcFlex class="items-center">
      <EcHeadline as="h4" variant="h4" class="text-c1-800"> Business Continuity Team Members </EcHeadline>
    </EcFlex>

    <!-- Add button and Search box -->
    <EcFlex class="flex-grow items-center w-full md:w-auto mt-8 mb-4 mr-4">
      <EcFlex class="justify-start w-1/2">
        <!-- Add button -->
        <EcButton
          v-permissions:division-create
          variant="primary-sm"
          class="mb-3 lg:mb-0"
          id="btn-add-team-member"
          iconPrefix="PlusCircle"
          @click="handleClickAddTeamMember"
        >
          {{ $t("organization.add") }}
        </EcButton>
      </EcFlex>

      <!-- Search box -->
      <EcFlex class="justify-end w-1/2">
        <RSearchBox
          v-model="searchQuery"
          :isSearched="searchQuery !== ''"
          :placeholder="$t('organization.searchTeamMember')"
          class="w-full md:max-w-xs text-sm"
          id="search-team-member"
          @search="handleSearch"
          @clear-search="handleClearSearch"
        />
      </EcFlex>
    </EcFlex>

    <!-- Divison list -->
    <EcFlex v-if="!isLoading" class="lg:flex-wrap grid grid-cols-1 md:grid-cols-2 gap-2">
      <TeamMemberListCardItem
        v-for="item in teamMembers"
        :teamMember="item"
        :key="item.uid"
        :isActive="selectedTeamMember?.uid === item.uid"
        @handleTeamMemberCardChange="handleTeamMemberCardChange"
        @handleDeletedTeamMember="handleDeletedTeamMember"
      />

      <!-- No data found -->
      <EcBox v-show="teamMembers.length === 0">
        <EcText>{{ $t("core.noDataHere") }}</EcText>
      </EcBox>
    </EcFlex>

    <EcFlex v-else class="mt-4">
      <EcSpinner />
    </EcFlex>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="currentPage"
        :limit="perPage"
        :totalCount="filters.page.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="currentPage"
        :itemPerPage="perPage"
        :totalItems="filters.page.total"
        @input="setPage($event)"
        @pageSizeChanged="setPerPage"
      />
    </EcFlex>
  </EcBox>
</template>

<script>
import TeamMemberListCardItem from "./TeamMemberListCardItem"
import { useTeamMemberList } from "../../use/teamMember/useTeamMemberList"
import EcFlex from "@/components/EcFlex/index.vue"
import EcSpinner from "@/components/EcSpinner/index.vue"
import { goto } from "@/modules/core/composables"
import { ref } from "vue"
import { APIQueryBuilder } from "@/readybc/composables/helpers/apiQuery/apiQuery"
import { TypeCompareEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export default {
  name: "TeamMemberList",
  components: {
    TeamMemberListCardItem,
    EcFlex,
    EcSpinner,
  },
  data() {
    // Filters
    const filters = {
      filter: [],
      page: {
        number: 1,
        total: 0,
      },
    }

    return {
      isLoading: false,
      searchQuery: "",
      onFilter: "",
      apiQueryBuilder: new APIQueryBuilder(),
      filters,
      perPage: 10,
    }
  },

  setup() {
    const teamMembers = ref([])
    const { currentPage, getTeamMembers } = useTeamMemberList()

    return {
      currentPage,
      getTeamMembers,
      teamMembers,
    }
  },

  emits: ["handleTeamMemberCardChangeOnManagement"],

  props: {
    selectedTeamMember: {
      type: Object,
    },
  },

  computed: {},

  mounted() {
    this.fetchTeamMembers()
  },

  methods: {
    /**
     * Fetch TeamMembers
     */
    async fetchTeamMembers() {
      this.isLoading = true

      const response = await this.getTeamMembers(this.apiQueryBuilder.toQuery())

      if (response && response.data) {
        this.teamMembers = response.data
      }

      // Pagination
      this.currentPage = response.current_page
      this.perPage = response.per_page
      this.filters.page.total = response.total

      this.isLoading = false
    },

    /**
     * Click Add teamMember
     */
    handleClickAddTeamMember() {
      goto("ViewTeamMemberNew")
    },

    handleTeamMemberCardChange(teamMember) {
      this.$emit("handleTeamMemberCardChangeOnManagement", teamMember)
    },

    handleDeletedTeamMember() {
      this.fetchTeamMembers();
    },

    /**
     * clear search input
     */
    handleClearSearch() {
      this.searchQuery = ""
      this.apiQueryBuilder.clearFilter("user_name")
      this.fetchTeamMembers()
    },

    /**
     *
     * @returns {Promise<void>}
     */
    async handleSearch() {
      if (this.searchQuery.length > 0) {
        this.apiQueryBuilder.setFilter("user_name", this.searchQuery, TypeCompareEnum.LIKE)
      } else {
        this.apiQueryBuilder.clearFilter("user_name")
      }
      await this.fetchTeamMembers()
    },

    setPerPage(perPage) {
      this.perPage = perPage;
    }
  },

  watch: {
    selectedTeamMember(teamMember) {
      this.$emit("handleTeamMemberCardChangeOnManagement", teamMember)
    },

    currentPage() {
      this.apiQueryBuilder.setPage(this.currentPage)
      this.fetchTeamMembers()
    },

    perPage() {
      this.apiQueryBuilder.setPerPage(this.perPage)
      this.fetchTeamMembers()
    },
  },
}
</script>
