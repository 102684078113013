<template>
  <EcMessage :messages="computedMessages" @close="close">
    <template v-slot="{ content }">
      <!-- Content is a string -->
      <EcBox v-if="content.type === 'message'">
        {{ content.text.content ?? content.text }}
      </EcBox>

      <!-- The code here will contain every cases supported to show by CMessage -->
      <router-link v-else-if="content.type === 'link'" :to="content.to">
        {{ content.linkText }}
      </router-link>

      <!-- The code here will contain every cases supported to show by CMessage -->
      <EcBox v-else-if="content.type === 'notify'" @click="notifyDetail(content)">
        <div class="flex max-w-sm cursor-pointer">
          <div class="flex-shrink-0">
            <EcIcon icon="Bell" />
          </div>
          <div class="ml-4">
            <h2 class="text-lg font-semibold line-clamp-2">{{ content.title }}</h2>
            <EcRichTextDisplay class="mt-1 text-md line-clamp-3" :data="content.message" />
          </div>
        </div>
      </EcBox>
    </template>
  </EcMessage>
</template>

<script>
import { useGlobalStore } from "@/stores/global"
import EcBox from "@/components/EcBox/index.vue"
import { goto } from "@/modules/core/composables"
import EcRichTextDisplay from "@/components/EcRichTextDisplay/index.vue"

export default {
  name: "RMessage",
  components: { EcRichTextDisplay, EcBox },
  props: {
    max: {
      type: Number,
      default: 5,
    },
  },
  setup(props) {
    const globalStore = useGlobalStore()

    return {
      globalStore,
    }
  },
  computed: {
    computedMessages() {
      const messages = this.globalStore.getMessages ?? {}
      const messagesList = Object.keys(messages)?.map((key) => ({
        ...messages[key],
      }))
      return messagesList?.slice(Math.max(messagesList?.length - this?.max, 0), messagesList?.length) ?? []
    },
  },
  methods: {
    close(key) {
      this.globalStore.removeToastMessage(key)
    },
    notifyDetail(notify) {
      const uid = notify.uid
      goto("ViewUserNotificationDetail", {
        params: {
          uid,
        },
      })
    },
  },
}
</script>
