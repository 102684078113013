import { ref } from "vue"
import * as api from "../../api/teamMemberFetcher"

export const useTeamMemberUserList = () => {
  const currentPage = ref(1)

  const getUsers = async (param) => {
    try {
      const { data } = await api.fetchTeamMemberUserList(param)

      return data
    } catch (error) { }
  }

  return { currentPage, getUsers }
}
