<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="grid grid-cols-2 items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ reportTemplate?.report_name ?? $t("reportTemplates.title") }}
        </EcHeadline>

        <EcFlex class="mt-3">
          <!-- Button Re-Upload Template Form -->
          <EcButton
            v-permissions:report_template-create
            class="mb-3 lg:mb-0"
            iconPrefix="plus-circle"
            variant="primary-sm"
            id="btn-show-re-upload-template"
            @click="handleClickReUploadTemplate"
          >
            {{ $t("reportTemplates.buttons.reUploadTemplate") }}
          </EcButton>

          <EcButton
            v-permissions:report_template-create
            class="mb-3 lg:mb-0 ml-2 px-1"
            iconPrefix="cursor-text"
            variant="primary-sm"
            id="btn-show-replacement-tokens"
            @click="handleGetReplacementTokens"
          >
            {{ $t("reportTemplates.buttons.replacementTokens") }}
          </EcButton>
        </EcFlex>
      </EcFlex>

      <!-- action button -->
    </EcFlex>

    <!-- Viewer -->

    <EcBox
      v-if="showFormReUploadTemplate"
      v-permissions:report_template-create
      class="w-full h-full border border-c0-200 border-dashed my-4"
    >
      <EcIcon icon="Close" class="cursor-pointer w-3 ml-2 mt-2" @click="showFormReUploadTemplate = false" />
      <EcBox class="width-full mt-4">
        <!-- form upload -->
        <EcFlex class="flex-wrap w-full max-w-full items-center justify-center">
          <EcBox class="w-2/3 mb-6 sm:pr-6">
            <RUploadFiles
              :dir="'report_templates'"
              :type="reportTemplate.type"
              :documentTitle="'Upload ' + reportTemplate?.report_name ?? ''"
              :isUploadOnSelect="false"
              :maxFileNum="1"
              :apiHandleUploadFile="uploadReportTemplate"
              :accept="acceptDocumentTypes"
              dropZoneCls="border-c0-500 border-dashed border-2 bg-cWhite p-2 md:py-4"
              @handleSingleUploadResult="handleReportTemplateUploadResult"
              @startUploadFiles="this.isCreating = true"
              @endUploadFiles="this.isCreating = false"
              :allowRename="true"
            />
          </EcBox>
        </EcFlex>
      </EcBox>
    </EcBox>

    <EcBox class="grid grid-cols-5 lg:mt-2">
      <!-- Data filter -->
      <EcFlex class="items-center">
        <RDataFilter
          ref="filterBoxRef"
          v-model="apiCriteria"
          :filterOptions="filterOptions"
          @appliedFilters="getReportTemplateRevisionList"
        />
      </EcFlex>
      <EcFlex class="col-span-4 grid grid-cols-2 gap-2"> </EcFlex>
    </EcBox>

    <EcTable
      ref="dataTableRef"
      name="tbl-report-templates"
      :isLoading="isTemplateRevisionLoading"
      :columns="headerData"
      :dataSource="listTemplateRevisions"
      :actionMenu="true"
      :defaultMenuItemVisible="tableMenuItemVisible"
      :rowClicked="handleClickReportTemplateRevision"
      @sorted="handleColumnSort"
      @onEdit="handleClickReportTemplateRevision"
      @onDelete="handleClickDeleteReportTemplateRevision"
    >
      <template v-slot:created_by="{ item }">
        <EcFlex class="items-center w-full">
          {{ item?.user ? `${item?.user?.first_name} ${item?.user?.last_name}` : "N/A" }}
        </EcFlex>
      </template>

      <template v-slot:name="{ item }">
        <EcBadge
          class="px-2 py-1 rounded-full min-w-24 !justify-start"
          :title="item?.is_default ? 'Default report template' : ''"
        >
          {{ item?.name }}
          <EcIcon icon="CheckCircle" class="text-cSuccess-500" width="16" height="16" v-if="item?.is_default" />
        </EcBadge>
      </template>

      <template v-slot:created_at="{ item }">
        <EcBadge class="px-2 py-1 rounded-full min-w-24">
          {{ formatData(item?.created_at, dateTimeFormat) }}
        </EcBadge>
      </template>
    </EcTable>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (size) => {
            apiCriteria.setPageSize(size)
          }
        "
      />
    </EcFlex>

    <!-- Modals -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalDeleteOpen" variant="center-3xl" @overlay-click="handleCloseDeleteModal">
        <EcBox class="text-center">
          <EcFlex class="justify-center">
            <EcIcon class="text-cError-500" width="30" height="30" icon="TrashAlt" />
          </EcFlex>

          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-2xl">
              {{ $t("reportTemplates.modal.title.confirmToDelete") }}
            </EcHeadline>
            <EcText class="text-c0-500 mt-4">
              {{ $t("reportTemplates.modal.cfDeleteNote") }}
            </EcText>
            <EcText class="text-lg font-semibold">
              {{ this.selectedToDeleteReportTemplate.name }}
            </EcText>
            <EcText class="text-c0-500 mt-2">
              {{ $t("reportTemplates.modal.cfDeleteNote2") }}
            </EcText>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton variant="warning" @click="handleDeleteReportTemplateRevision">
              {{ $t("reportTemplates.buttons.delete") }}
            </EcButton>
            <EcButton class="ml-3" variant="tertiary-outline" @click="handleCloseDeleteModal">
              {{ $t("reportTemplates.buttons.cancel") }}
            </EcButton>
          </EcFlex>

          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>
    </teleport>

    <teleport to="#layer2">
      <ModalReplacementTokens
        :isModalOpen="isModalReplacementTokenOpen"
        :type="this.templateType"
        @close="
          () => {
            isModalReplacementTokenOpen = false
          }
        "
      />
    </teleport>
  </RLayout>
</template>

<script>
import { useGlobalStore } from "@/stores/global"
import { useReportTemplate } from "@/modules/report/use/useReportTemplate"
import { useReportTemplateRevisionList } from "@/modules/report/use/useReportTemplateRevisionList"
import { formatData, goto } from "@/modules/core/composables"
import { ReportType } from "@/modules/report/constants/report_type"
import ModalReplacementTokens from "../components/ModalReplacementTokens.vue"

export default {
  name: "ViewReportTemplates",
  components: { ModalReplacementTokens },
  data() {
    return {
      reportTemplate: null,
      showFormReUploadTemplate: false,
      selectedToDeleteReportTemplate: null,
      isCreating: false,
      headerData: [
        {
          key: "name",
          title: this.$t("reportTemplates.labels.reportType"),
          sortable: true,
        },
        {
          key: "revision",
          title: this.$t("reportTemplates.labels.revision"),
          sortable: true,
        },
        {
          key: "created_at",
          title: this.$t("reportTemplates.labels.createdAt"),
          sortable: true,
        },
        {
          key: "created_by",
          title: this.$t("reportTemplates.labels.createdBy"),
          sortable: true,
        },
        {
          key: "status",
          title: this.$t("reportTemplates.labels.status"),
          sortable: true,
        },
      ],
      filterOptions: [
        {
          key: "revision",
          title: this.$t("reportTemplates.labels.revision"),
        },
        {
          key: "created_at",
          title: this.$t("reportTemplates.labels.createdAt"),
          type: "date",
        },
      ],
      acceptDocumentTypes: ["docx", "doc"],
      tableMenuItemVisible: {
        edit: this.$permissions("report_template.read"),
        delete: this.$permissions("report_template.delete"),
      },
      currentPage: 1,
      isLoading: false,
      isDeleteLoading: false,
      isRevisionLoading: false,
      isModalDeleteOpen: false,
      isModalReplacementTokenOpen: false,
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const { fetchReportTemplateTypeDetails, uploadReportTemplate, deleteReportTemplateRevision } = useReportTemplate()

    const {
      templateType,
      listTemplateRevisions,
      apiCriteria,
      isTemplateRevisionLoading,
      handleColumnSort,
      getReportTemplateRevisionList,
    } = useReportTemplateRevisionList()

    return {
      globalStore,
      templateType,
      apiCriteria,
      listTemplateRevisions,
      isTemplateRevisionLoading,
      handleColumnSort,
      getReportTemplateRevisionList,
      fetchReportTemplateTypeDetails,
      uploadReportTemplate,
      deleteReportTemplateRevision,
      formatData,
    }
  },
  beforeMount() {
    const { type } = this.$route.params
    this.templateType = type
    if (this.templateType === ReportType.BIA && !this.globalStore.isLandlord) {
      goto("ViewConfigurableReportTemplates")
    }
  },
  async mounted() {
    await this.apiCriteria.bindCriteria()

    this.$refs.filterBoxRef?.handleSetActiveConditions(this.apiCriteria.getSearchCriteria())
    this.$refs.dataTableRef?.setColumnActiveSort(...this.apiCriteria.getActiveSort())
    this.fetchTemplateReportDetail()
    this.getReportTemplateRevisionList()
  },
  computed: {
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
  },
  methods: {
    /**
     * Fetch template report detail
     */
    async fetchTemplateReportDetail() {
      this.isLoading = true
      const response = await this.fetchReportTemplateTypeDetails(this.templateType)
      if (response) {
        this.reportTemplate = response.data
      }
      this.isLoading = false
    },

    /**
     *
     * @param {*} result
     */
    handleReportTemplateUploadResult(result) {
      this.globalStore.addSuccessToastMessage(this.$t("reportTemplates.messages.uploadReportTemplateSuccess"))
      this.getReportTemplateRevisionList()
      this.showFormReUploadTemplate = false
    },

    /**
     * Re-upload template
     */
    handleClickReUploadTemplate() {
      this.showFormReUploadTemplate = true
    },

    /**
     * Open replacement token modal
     */
    handleGetReplacementTokens() {
      this.isModalReplacementTokenOpen = true
    },

    /**
     * Close delete modal
     */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
      this.selectedToDeleteReportTemplate = null
    },

    /**
     *
     * @param {*} reportTemplate
     */
    handleClickDeleteReportTemplateRevision(reportTemplate) {
      this.isModalDeleteOpen = true
      this.selectedToDeleteReportTemplate = reportTemplate
    },

    /**
     * Delete template revision
     */
    async handleDeleteReportTemplateRevision() {
      const response = await this.deleteReportTemplateRevision(this.templateType, this.selectedToDeleteReportTemplate.uid)
      this.handleCloseDeleteModal()
      if (response) {
        this.globalStore.addSuccessToastMessage(this.$t("reportTemplates.messages.deleteReportTemplateSuccess"))
        await this.getReportTemplateRevisionList()
      }
    },

    /**
     *
     * @param {*} revision
     */
    handleClickReportTemplateRevision(revision) {
      goto("ViewReportTemplateRevision", {
        params: {
          type: this.reportTemplate.type,
          uid: revision.uid,
        },
      })
    },
  },
}
</script>
