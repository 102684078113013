import { ref } from "vue"
import * as api from "../api/userFetcher"
import { useGlobalStore } from "@/stores/global"
import { handleErrorForUser } from "../api"
import { useI18n } from "vue-i18n"
// import dayjs from "dayjs"

export function useUserOptions() {
  // Initial data
  const globalStore = useGlobalStore()
  const isLoading = ref(false)
  const users = ref([])
  const { t } = useI18n()

  // Fetch user lists
  async function fetchUserOptions() {
    try {
      isLoading.value = true
      const { data } = await api.getUserOptions()

      if (!data || data.error) {
        handleErrorForUser({ error: data?.error, $t: t })
      }

      if (data && data.data) {
        users.value = data.data
      }

      isLoading.value = false
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("user.errors.listUser"))
      isLoading.value = true
    }
  }

  return {
    fetchUserOptions,
  }
}
