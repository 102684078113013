<template>
  <EcBox
    variant="card-2"
    class="relative mb-2 p-2 mr-3 lg:inline-flex lg:flex-grow lg:w-auto hover:cursor-pointer team-member-item"
    style="min-width: 12rem"
    @click="$emit('handleTeamMemberCardChange', teamMember)"
  >
    <EcFlex class="absolute top-0 right-2.5 flex flex-row justify-end mt-2">
      <EcBox v-if="teamMember.user_name" v-permissions:division-update class="ml-2">
        <EcButton
          variant="transparent-rounded"
          class="btn-edit-team-member"
          @click="handleClickEdit"
          v-tooltip="{ text: 'Edit Team Member' }"
        >
          <EcIcon icon="Pencil" width="20" height="20" class="text-c1-800" />
        </EcButton>
      </EcBox>
      <EcBox v-if="teamMember.user_name" v-permissions:division-delete class="ml-2">
        <EcButton
          variant="transparent-rounded"
          class="btn-delete-tm"
          @click="handleOpenModalDeleteTeamMember"
          v-tooltip="{ text: 'Delete Team Member' }"
        >
          <EcIcon class="text-cError-500" icon="Trash" width="20" height="20" />
        </EcButton>
      </EcBox>
    </EcFlex>
    <EcBox class="mt-2">
      <EcText class="font-medium text-cBlack">
        {{ teamMember.user_name }}
      </EcText>
      <EcText class="font-medium text-base text-c0-500">
        {{ teamMember.user_email }}
      </EcText>

      <EcText class="font-medium text-c0-500 text-sm mt-2">
        <div>Role: {{ teamMember.organisation_role }}</div>
        <div>Business Continuity Role: {{ teamMember.bc_team_role }}</div>
      </EcText>
      <EcText class="font-medium text-c0-500 text-sm mt-2">{{ globalStore.formatDate(teamMember.created_at) }}</EcText>
    </EcBox>
  </EcBox>

  <!-- Modals -->
  <teleport to="#layer2">
    <!-- Modal Delete -->
    <EcModalSimple
      :isVisible="isModalDeleteOpen"
      variant="center-3xl"
      id="modal-delete-team-member"
      @overlay-click="handleCloseDeleteModal"
    >
      <EcBox class="text-center">
        <EcFlex class="justify-center">
          <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
        </EcFlex>

        <!-- Messages -->
        <EcBox>
          <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
            {{ $t("organization.confirmToDelete") }}
          </EcHeadline>
          <!-- Team Member name -->
          <EcText class="text-lg">
            {{ teamMember?.user_name }}
          </EcText>
          <EcText class="text-c0-500 mt-4">
            {{ $t("organization.teamMember.confirmDeleteQuestion") }}
          </EcText>
        </EcBox>

        <!-- Actions -->
        <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
          <EcButton variant="warning" @click="handleClickDeleteTeamMember">
            {{ $t("organization.teamMember.delete") }}
          </EcButton>
          <EcButton class="ml-3" variant="tertiary-outline" @click="handleCloseDeleteModal">
            {{ $t("organization.teamMember.cancel") }}
          </EcButton>
        </EcFlex>
        <EcFlex v-else class="items-center justify-center mt-10 h-10">
          <EcSpinner type="dots" />
        </EcFlex>
      </EcBox>
    </EcModalSimple>
  </teleport>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { generateAvatar } from "../../use/teamMember/useTeamMemberAvatar"
import { useGlobalStore } from "@/stores/global"
import { useTeamMemberDetail } from '../../use/teamMember/useTeamMemberDetail'

export default {
  name: "TeamMemberListCardItem",
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    teamMember: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const globalStore = useGlobalStore()
    const { deleteTeamMember } = useTeamMemberDetail()
    const organizationUid = ""

    return {
      generateAvatar,
      globalStore,
      organizationUid,
      deleteTeamMember,
    }
  },

  mounted() {
    const { organizationUid } = this.$route.params
    this.organizationUid = organizationUid
  },
  data() {
    return {
      isModalDeleteOpen: false,
      isDeleteLoading: false,
    }
  },
  emits: ['handleDeletedTeamMember'],
  methods: {
    /**
     *
     * @param {*} status
     */
    statusText(status) {
      return status ? "font-bold text-cSuccess-500" : "font-bold text-cError-500"
    },

    /**
     * Click edit
     */
    handleClickEdit() {
      goto("ViewTeamMemberDetail", {
        params: {
          teamMemberUid: this.teamMember?.uid,
        },
      })
    },

    /**
     * Click manage organization
     */
    handleClickManageTeamMember() {
      goto("ViewBusinessUnitList", {
        params: {
          teamMemberUid: this.teamMember?.uid,
        },
      })
    },

    /** Handle add new activity from current Team Member */
    handleClickAddActivity(bulkMode = false) {
      const routeName = bulkMode ? "ViewActivityBulkCreate" : "ViewActivityNew"
      goto(routeName, {
        query: {
          teamMember: this.teamMember?.uid,
        },
      })
    },

    /**
     * Delete Team Member
     */
    async handleClickDeleteTeamMember() {
      this.isDeleteLoading = true
      await this.deleteTeamMember(this.teamMember.uid)
      this.isDeleteLoading = false
      this.$emit("handleDeletedTeamMember")
      this.handleCloseDeleteModal()
    },

    /**
     * Handle click delete Team Member
     */
    handleOpenModalDeleteTeamMember() {
      this.isModalDeleteOpen = true
    },

    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },
  },
}
</script>
