<template>
  <RLayout :title="$t('organization.teamMember.edit')">
    <RLayoutTwoCol v-if="!isLoading">
      <template #left>
        <EcBox variant="card-1" class="width-full px-4 sm:px-10">
          <EcBox v-if="teamMember">

            <!-- Name -->
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model.trim="teamMember.user_name"
                  componentName="EcInputText"
                  :label="$t('organization.name')"
                  :validator="v$"
                  field="teamMember.user_name"
                  @keyup="handleInputName"
                  @input="v$.teamMember.user_name.$touch()"
                />
              </EcBox>
            </EcFlex>

            <!-- Email -->
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model.trim="teamMember.user_email"
                  componentName="EcInputText"
                  :label="$t('organization.teamMember.email')"
                  :validator="v$"
                  field="teamMember.user_email"
                  @keyup="handleInputName"
                  @input="v$.teamMember.user_email.$touch()"
                />
              </EcBox>
            </EcFlex>

            <!-- Phone Number -->
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model.trim="teamMember.phone_number"
                  componentName="EcInputText"
                  :label="$t('organization.teamMember.phone')"
                  :validator="v$"
                  field="teamMember.phone_number"
                  @keyup="handleInputName"
                  @input="v$.teamMember.phone_number.$touch()"
                />
              </EcBox>
            </EcFlex>

            <!-- Organisation Role -->
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model.trim="teamMember.organisation_role"
                  componentName="EcInputText"
                  :label="$t('organization.teamMember.organisationRole')"
                  :validator="v$"
                  field="teamMember.organisation_role"
                  @keyup="handleInputName"
                  @input="v$.teamMember.organisation_role.$touch()"
                />
              </EcBox>
            </EcFlex>

            <!-- Business Continuity Role -->
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model.trim="teamMember.bc_team_role"
                  componentName="EcInputText"
                  :label="$t('organization.teamMember.bcRole')"
                  :validator="v$"
                  field="teamMember.bc_team_role"
                  @keyup="handleInputName"
                  @input="v$.teamMember.bc_team_role.$touch()"
                />
              </EcBox>
            </EcFlex>

          </EcBox>
        </EcBox>
        <!-- Actions -->
        <EcBox class="width-full mt-8 px-4 sm:px-10">
          <!-- Button create -->
          <EcFlex v-if="!isUpdateLoading" class="mt-6">
            <EcButton variant="primary" id="btn-update" @click="handleClickUpdate">
              {{ $t("organization.teamMember.update") }}
            </EcButton>
            <EcButton class="ml-4" variant="tertiary-outline" id="btn-back" @click="handleClickCancel">
              {{ $t("organization.teamMember.back") }}
            </EcButton>
          </EcFlex>

          <!-- Loading -->
          <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
        </EcBox>
      </template>

      <!-- Delete -->
      <template #right>
        <!-- Delete organization -->
        <EcBox variant="card-1" class="mb-8">
          <EcHeadline as="h2" variant="h2" class="text-c1-800 px-5">
            {{ $t("organization.teamMember.deleteHeader") }}
          </EcHeadline>
          <EcText class="px-5 my-6 text-c0-500 leading-normal">
            {{ $t("organization.teamMember.deleteNote") }}
          </EcText>
          <EcButton class="mx-5" variant="warning" iconPrefix="Trash" id="btn-modal-delete" @click="handleOpenDeleteModal">
            {{ $t("organization.teamMember.deleteButton") }}
          </EcButton>
        </EcBox>
      </template>
    </RLayoutTwoCol>

    <RLoading v-else />

    <!-- Modals -->
    <teleport to="#layer2">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalDeleteOpen" variant="center-3xl" @overlay-click="handleCloseDeleteModal">
        <EcBox class="text-center">
          <EcFlex class="justify-center">
            <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
          </EcFlex>

          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
              {{ $t("organization.confirmToDelete") }}
            </EcHeadline>
            <!-- Org name -->
            <EcText class="text-lg">
              {{ this.teamMember.name }}
            </EcText>
            <EcText class="text-c0-500 mt-4">
              {{ $t("organization.teamMember.confirmDeleteQuestion") }}
            </EcText>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton variant="warning" id="btn-delete" @click="handleDeleteTeamMember">
              {{ $t("organization.teamMember.delete") }}
            </EcButton>
            <EcButton class="ml-3" variant="tertiary-outline" id="btn-cancel" @click="handleCloseDeleteModal">
              {{ $t("organization.teamMember.cancel") }}
            </EcButton>
          </EcFlex>
          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>
    </teleport>
  </RLayout>
</template>

<script>
import { useTeamMemberDetail } from "../../use/teamMember/useTeamMemberDetail"
import { goto } from "@/modules/core/composables"
import { ref } from "vue"
import RLoading from "@/modules/core/components/common/RLoading.vue"
import RFormInput from "@/modules/core/components/common/RFormInput.vue"
import RLayoutTwoCol from "@/modules/core/components/common/RLayoutTwoCol.vue"

export default {
  name: "ViewOrganizationNew",
  data() {
    return {
      isModalDeleteOpen: false,
      organizationUid: "",
      isLoading: false,
      isUpdateLoading: false,
      isDeleteLoading: false,
      logoTitle: "Logo",
    }
  },
  mounted() {
    const { organizationUid } = this.$route.params
    this.organizationUid = organizationUid
    this.fetchTeamMember()
  },
  setup() {
    const { v$, teamMember, getTeamMember, updateTeamMember, deleteTeamMember } = useTeamMemberDetail()
    const industries = ref([])
    return {
      v$,
      teamMember,
      getTeamMember,
      updateTeamMember,
      deleteTeamMember,
      industries,
    }
  },
  computed: {
  },
  methods: {
    /**
     * Fetch teamMember with uid
     */
    async fetchTeamMember() {
      const { teamMemberUid } = this.$route.params

      this.isLoading = true
      const response = await this.getTeamMember(teamMemberUid)
      this.isLoading = false

      if (response && response.uid) {
        this.teamMember = response
      }
    },
    /**
     * Update teamMember
     */
    async handleClickUpdate() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.isUpdateLoading = true
      const response = await this.updateTeamMember(this.teamMember, this.teamMember.uid)
      this.isUpdateLoading = false

      if (response && response.uid) {
        this.teamMember = response
        setTimeout(this.handleUpdateSuccess, 1000)
      }
    },

    /**
     * Delete teamMember
     */
    async handleDeleteTeamMember() {
      this.isDeleteLoading = true

      await this.deleteTeamMember(this.teamMember.uid)

      setTimeout(this.handleUpdateSuccess, 1000)
      this.isDeleteLoading = false
    },
    /**
     * Back to organization list
     */
    handleUpdateSuccess() {
      this.gotoDepartmentManagement()
    },
    /**
     * Back to organization list
     */
    handleClickCancel() {
      this.gotoDepartmentManagement()
    },

    /** Open delete modal */
    handleOpenDeleteModal() {
      this.isModalDeleteOpen = true
    },

    /** Close delete modal */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },

    /**
     * Go to Department Management
     */
    gotoDepartmentManagement() {
      goto("ViewDepartmentManagement")
    },
  },
  components: { RLoading, RFormInput, RLayoutTwoCol },
}
</script>
