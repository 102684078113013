<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("activity.title.editActivity") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Step bar -->
    <EcStepBar
      :options="filteredSteps"
      :current="form.step"
      :onStep="onStep"
      :clickable="!isLoading"
      @onClick="handleClickSave"
    />

    <!-- Body -->
    <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <!-- Title and cancel button -->
      <EcFlex>
        <EcText class="w-11/12 font-bold text-lg mb-4">{{ $t("activity.title.dependencies") }}</EcText>
      </EcFlex>
      <!-- internal dependencies -->
      <EcBox class="w-full mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.labels.internal_dependencies") }}</EcLabel>
          <!-- Add button -->
          <EcButton
            v-if="!isRefreshInternalDependency"
            class="ml-2"
            variant="primary-rounded"
            @click="handleAddMoreInternalDependency"
            v-tooltip="{ text: $t('activity.tooltips.addInternalDependency') }"
          >
            <EcIcon icon="Plus" width="16" height="16" />
          </EcButton>
          <!-- refresh -->
          <EcButton
            v-else
            class="ml-2"
            variant="primary-rounded"
            @click="fetchInternalDependencies"
            v-tooltip="{ text: $t('activity.tooltips.internalDependencyReload') }"
          >
            <EcIcon icon="Refresh" width="16" height="16" />
          </EcButton>
        </EcFlex>

        <!-- dependencies -->
        <EcFlex class="max-w-md items-center mb-8">
          <EcBox class="w-full sm:w-12/20 sm:pr-6">
            <RFormInput
              v-model="form.internal_dependencies"
              componentName="EcMultiSelect"
              :options="filteredInternalDependencies"
              :valueKey="'uid'"
              :nameKey="`transformName`"
              :validator="v$"
              field="form.internal_dependencies"
            />
          </EcBox>
          <EcSpinner class="my-auto mb-1" v-if="isLoadingInternalDependencies"></EcSpinner>
        </EcFlex>
      </EcBox>
      <!-- End add more dependencies -->

      <!-- suppliers -->
      <EcBox class="w-full mb-8">
        <EcFlex>
          <EcLabel class="text-base font-medium"> {{ $t("activity.labels.suppliers") }}</EcLabel>
        </EcFlex>

        <EcFlex class="max-w-md items-center mb-8">
          <EcBox class="w-full sm:w-12/20 sm:pr-6">
            <RFormInput
              v-model="form.suppliers"
              componentName="EcMultiSelect"
              :options="filteredSuppliers"
              :valueKey="'uid'"
              :validator="v$"
              :addNewOnNotFound="true"
              :noDataPlaceholder="$t('activity.placeholders.noData')"
              @addNewOptionOnNotFound="handleAddNewSupplierSelectOption"
            />
          </EcBox>
          <EcSpinner class="my-auto mb-1" v-if="isLoadingSuppliers"></EcSpinner>
        </EcFlex>
      </EcBox>
      <!-- End suppliers -->

      <!-- bcm accessed -->
      <EcBox v-if="form.suppliers?.length > 0" class="w-full">
        <EcLabel class="text-base font-medium"> {{ $t("activity.labels.bcm_has_been_accessed") }}</EcLabel>
        <EcBox v-for="(supplier, index) in form.suppliers" :key="index" class="max-w-md items-center mt-2 ml-4 pr-4">
          <RFormInput
            class="w-full sm:w-12/20 sm:pr-6"
            v-model="supplier.assessed_methods"
            :label="$t('activity.labels.bcm_accessed_method', { supplierName: supplier.name })"
            componentName="EcInputText"
          />
        </EcBox>
      </EcBox>
      <!-- End bcm accessed -->

      <!-- End body -->
    </EcBox>

    <!-- Actions -->
    <EcBox class="width-full mt-8 px-4 sm:px-10">
      <!-- Button back -->
      <EcFlex v-if="!isLoading" class="mt-6">
        <EcButton variant="tertiary-outline" id="btn-back" @click="handleClickBack">
          {{ $t("activity.buttons.back") }}
        </EcButton>

        <EcButton variant="primary" class="ml-4" id="btn-next" @click="handleClickNext">
          {{ $t("activity.buttons.next") }}
        </EcButton>

        <EcButton variant="primary" class="ml-8" id="btn-save" @click="handleClickSave">
          {{ $t("activity.buttons.save") }}
        </EcButton>
      </EcFlex>

      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
    </EcBox>
    <!-- End actions -->

    <!-- Modals -->
    <teleport to="#layer2">
      <ModalCancelAddActivity :isModalCancelOpen="isModalCancelOpen" @handleCloseCancelModal="handleCloseCancelModal" />
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { ref } from "vue"
import ModalCancelAddActivity from "../components/ModalCancelAddActivity.vue"
import { useActivityDetail } from "../use/useActivityDetail"
// import { useDependencyList } from "@/modules/dependency/use/dependency/useDependencyList"
// import { useSupplierList } from "@/modules/supplier/use/supplier/useSupplierList"
import { useBusinessUnitList } from "../use/useBusinessUnitList"
import { useSupplier } from "../use/useSupplier"
import { useDependenciesAndSuppliers } from "@/modules/activity/use/useDependenciesAndSuppliers"
import { useActivityStage } from "@/modules/activity/use/useActivityStage"

export default {
  name: "ViewActivityApplication",
  props: {
    uid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isModalCancelOpen: false,
      isLoading: false,
      isLoadingInternalDependencies: false,
      isLoadingSuppliers: false,
      isRefreshInternalDependency: false,
      isRefreshSupplier: false,
    }
  },
  setup() {
    // PRE LOAD

    const { getSupplierList } = useSupplier()
    const { getBusinessUnitList } = useBusinessUnitList()

    // Functions
    const { form, v$, updateDependenciesAndSuppliers } = useDependenciesAndSuppliers()
    const { getActivity } = useActivityDetail()

    const suppliers = ref([])
    const internalDependencies = ref([])

    return {
      form,
      v$,
      suppliers,
      internalDependencies,
      getActivity,
      getBusinessUnitList,
      getSupplierList,
      updateDependenciesAndSuppliers,
    }
  },

  computed: {
    filteredSteps() {
      return useActivityStage.updateActivityStages()
    },

    /**
     * Filter suppliers
     */
    filteredSuppliers() {
      const selectedSupplierIds = this.form.suppliers?.map((item) => {
        return item.uid
      })
      return this.suppliers.map((supplier) => {
        supplier.disabled = selectedSupplierIds.includes(supplier.uid)

        return supplier
      })
    },

    onStep() {
      return useActivityStage.DEPENDENCY_SUPPLIER
    },
    /**
     * Filter internal dependency
     */
    filteredInternalDependencies() {
      const selectedInternalDependencyUIDs = this.form.internal_dependencies.map((app) => {
        return app.uid
      })
      return this.internalDependencies.map((app) => {
        app.disabled = selectedInternalDependencyUIDs.includes(app.uid)

        return app
      })
    },
  },
  mounted() {
    this.fetchActivity()
    this.fetchSuppliers()
    this.fetchInternalDependencies()
  },

  methods: {
    /**
     * Save dependencies
     */
    async handleClickNext() {
      this.v$.form.$touch()

      if (this.v$.form.$invalid) {
        return
      }

      this.isLoading = true

      const response = await this.updateDependenciesAndSuppliers(this.form, this.uid)

      if (response && response.uid) {
        setTimeout(this.redirectToNextStep, 1000)
      }
      this.isLoading = false
    },
    /**
     * Save dependencies and redirect to list activities
     */
    async handleClickSave(redirect = true) {
      this.v$.form.$touch()
      if (this.v$.form.$invalid) {
        return
      }

      this.isLoading = true

      const response = await this.updateDependenciesAndSuppliers(this.form, this.uid)

      if (response && response.uid && redirect) {
        setTimeout(this.redirectToActivityList, 1000)
      }
      this.isLoading = false
    },
    /**
     * Redirect to activity list
     */
    redirectToActivityList() {
      goto("ViewActivityList")
    },

    /**
     * Redirect to next step
     */
    redirectToNextStep() {
      goto("ViewActivityUpdateTolerant")
    },

    // =========== Dependency_scenarios ================ //
    /**
     * open new tab to add dependency
     */
    handleAddMoreInternalDependency() {
      this.isRefreshInternalDependency = true
      const route = this.$router.resolve({ path: "/organisation" })
      window.open(route.href)
    },

    /**
     * Back to pre Step
     */
    handleClickBack() {
      goto("ViewActivityUpdateApplication")
    },

    /**
     * Open cancel modal
     */
    handleOpenCancelModal() {
      this.isModalCancelOpen = true
    },

    /**
     * Open cancel modal
     */
    handleCloseCancelModal() {
      this.isModalCancelOpen = false
    },

    /**
     * Fetch Activity
     */
    async fetchActivity() {
      this.isLoading = true

      const response = await this.getActivity(this.uid, ["suppliers", "internalDependencies.division"])

      if (response && response.uid) {
        this.transformFormData(response)
      }

      this.isLoading = false
    },

    /**
     * Transform data
     */
    transformFormData(response) {
      this.form.step = response.step

      // supplier
      if (response.suppliers.length > 0) {
        this.form.suppliers = response.suppliers
        this.form.suppliers?.forEach((supplier) => {
          supplier.assessed_methods = supplier.pivot?.metadata?.assessed_methods ?? null
        })
      }

      // internal_dependencies
      if (response.internal_dependencies.length > 0) {
        this.form.internal_dependencies = this.transformInternalDependencies(response.internal_dependencies)
      }
    },

    /**
     * Applications
     */
    async fetchSuppliers() {
      this.isRefreshSupplier = false
      this.isLoadingSuppliers = true
      const response = await this.getSupplierList()

      if (response && response.data) {
        this.suppliers = response.data
      }

      this.isLoadingSuppliers = false
    },

    /**
     * Equipments
     */
    async fetchInternalDependencies() {
      this.isRefreshInternalDependency = false
      this.isLoadingInternalDependencies = true
      const response = await this.getBusinessUnitList(true)
      if (response && response.data) {
        this.internalDependencies = this.transformInternalDependencies(response.data)
      }

      this.isLoadingInternalDependencies = false
    },
    /**
     *
     * @param data
     * @returns {*}
     */
    transformInternalDependencies(data) {
      return data
        .map((item) => {
          item.transformName = this.transformBUName(item)
          return item
        })
        .sort(function (a, b) {
          const x = a.transformName.toLowerCase()
          const y = b.transformName.toLowerCase()
          return x < y ? -1 : x > y ? 1 : 0
        })
    },
    /**
     *
     * @param bu
     * @returns {*}
     */
    transformBUName(bu) {
      return (bu.division ? bu.division.name + " - " : "") + bu.name
    },
    /**
     * Open Add New Supplier modal
     */
    handleOpenAddNewSupplierModal() {
      this.isModalAddNewSupplierOpen = true
    },

    async handleCallbackAddNewSupplier(supplier) {
      await this.fetchSuppliers()
      this.form.suppliers.push(supplier)
    },

    /**
     *
     * @param {*} option
     */
    handleAddNewSupplierSelectOption(option) {
      this.suppliers.push(option)
    },
  },
  components: { ModalCancelAddActivity },
}
</script>
