import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { useGlobalStore } from "@/stores/global"
import * as api from "../../api/teamMemberFetcher"

export const useTeamMemberNew = () => {
  // Global store
  const globalStore = useGlobalStore()

  const form = ref({
    user_uid: null,
    user_name: "",
    user_email: "",
    phone_number: "",
    organisation_role: "",
    bc_team_role: "",
  })

  const rules = {
    form: {
      user_uid: {},
      user_name: {},
      user_email: {},
      phone_number: { required },
      organisation_role: { required },
      bc_team_role: { required },
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const createTeamMember = async (payload) => {
    try {
      const { data } = await api.createTeamMember(payload)

      globalStore.addSuccessToastMessage("Created Team Member")

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  return {
    form,
    v$,
    createTeamMember,
  }
}
