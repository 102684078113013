export default {
  core: {
    // Sidebar menu
    dashboard: "Dashboard",
    users: "Users",
    organizations: "Organisations",
    departments: "Organisation",
    report: "Report",
    equipment: "Equipment",
    suppliers: "Suppliers",
    dependencies: "Dependencies",
    dependenciesv2: "Critical Relationships",
    industries: "Industries",
    activities: "Activities",
    contunity5: "Continuity 5",
    people: "People (Coming soon)",
    location: "Location (Coming soon)",
    applications: "IT Apps/Systems",
    reports: "Reports",
    listReports: "List Reports",
    generateReport: "Generate Report",
    settings: "Settings",
    assessment: "BIA Summary",
    business_continuity_plan: "BCP",
    resiliencePlan: "Resilience Plan",
    bcp_tooltip: "Business Continuity Plan (BCP)",
    logout: "Log Out",
    continue: "Continue",
    idleTimeout: "You have been idle for a while. To keep the data secure, you will be signed out in a little while.",
    idleQuestion: "Are you still there?",

    // Others
    edit: "Edit",
    delete: "Delete",
    required: "This field is required!",
    requiredIf: "This field is required!",
    email: "Please enter a valid email address",
    alpha: "This field may only contain alphabetic characters",
    alphaNum: "Accepts only alphanumerics.",
    maxLength: "This field exceeds the maximum length",
    minLength: "This field needs at least {min} characters",
    sameAs: "This field does not match",
    diffWith: "This field must be different from {current}",
    numeric: "Only numeric is accepted",
    noNumber: "This field can't contain number",
    phone: "Please enter a valid Phone Number",
    nameValidator: "The name field only contains alphabetic characters, hyphens, and spaces",
    phoneValidator: "Please enter a valid phone number.",
    subDomainValidator: "The sub-domain should only use letters, numbers, hyphens, and underscores",
    notContainHtmlTagValidator: "HTML tags are not allowed!",
    mtpdMustGreaterThenRTOValidator: "The selected RTO is currently higher than your MTPD selection",
    rtoMustLessThenMTPDValidator: "The RTO selection is currently higher than your selected MTPD",
    // End validator messsages

    noDataHere: "No data found",
    notifications: "Notifications",
    credentials: "Credentials",
    name: "Name",
    gender: "Gender",
    salutation: "Salutation",
    dateOfBirth: "Date Of Birth",
    maritalStatus: "Marital Status",
    countryOfResidence: "Country Of Residence",
    acceptsMarketing: "Accepts Marketing",
    addresses: "Addresses",
    identities: "Identities",
    facts: "Facts",
    relationships: "Relationships",
    cancel: "Cancel",
    update: "Update",
    add: "Add",
    back: "Back",
    confirm: "Confirm",
    type: "Type",
    value: "Value",
    addField: "Add Field",
    deleteAddress: "Delete Address",
    addressType: "Address Type",
    ok: "Ok",
    individuals: "Individuals",
    companies: "Companies",
    objects: "Objects",
    internals: "Internals",
    select: "Select",
    selectEntityType: "Please select an Entity Type",
    selectEntity: "Select an Entity",
    noEntityFound: "No Entity Found",
    relationshipType: "Relationship Type",
    individual: "Individual",
    company: "Company",
    object: "Object",
    internal: "Internal",
    organization: "Organisation",
    yes: "Yes",
    no: "No",
    or: "or",
    browse: "Browse",
    documents: "Documents",
    default_dir: "misc",
    noFiles: "There are no files yet",
    noFiles_upload: "No file to upload",
    dragDropHere: "Drag and Drop Files Here",
    upload: "Upload",
    uploading: "Uploading...",
    failed: "Failed",
    inProgress: "In progress",
    ready: "Ready to upload",
    uploaded: "Uploaded",
    existed: "Existed",
    invalidExtension: "Invalid Extension",
    existedFileName: "Do you want to upload and replace original?",
    count: "Count",
    showing: "Showing",
    of: "of",
    fileNotSupported: "The file in {type} type is not supported!",
    fileHasExisted: "The file {name} has existed",
    fileExisted: "Some file already exists. Change its name and upload it again.",
    label: "Label",
    text: "Text",
    number: "Number",
    boolean: "Boolean",
    date: "Date",
    code: "Code",
    notes: "Notes",
    filesNotFound: "Files Not Found",
    addNew: "Add new",
    editFor: "Edit for",
    addNotes: "Add Notes",
    countryOfResidency: "Country Of Residency",
    shareToClient: "Share to Client",
    selectDate: "Select Date",
    today: "Today",
    lastWeek: "Last Week",
    lastMonth: "Last Month",
    lastThreeMonths: "Last 3 Months",
    lastSixMonths: "Last 6 Months",
    lastYear: "Last Year",
    between: "Between",
    before: "Before",
    after: "After",
    clear: "Clear",
    noName: "No Name",
    title: "Title",
    pleaseSelect: "Please Select",
    age: "Age",
    list: "List",
    addRow: "Add Row",
    remove: "Remove",
    comment: "Comment",
    CHEQUE: "Cheque",
    ONE_TIME: "One Time",
    MONTHLY: "Monthly",
    QUARTERLY: "Quarterly",
    SEMI_ANNUALLY: "Semi Annually",
    ANNUALLY: "Annually",
    download: "Download",
    copyLinkSuccess: "Copied",
    searchHere: "Search here",

    errors: {
      accessDenied: "Sorry, you do not have permission to access this resource. Please contact the administrator for assistance.",
    },
  },
}
