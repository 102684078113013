export default {
  dashboard: {
    dashboard: "Dashboard",
    clients: "Clients",
    policies: "Policies",
    notifications: "Notifications",
    activities: "Activities",
    organizations: "Organisations",
    tasks: "Tasks",
    users: "Users",
    cases: "Cases",

    activity: {
      organisationActivityTitle: "Organisation Activities Summary",
      denepdentBU: "Dependent Business Units  ",
      unassignedSBU: "Unassigned SBUs ",
      criticalActivityTitle: "List of Critical Business Activities",
      noActivity: "No critical business activities identified",
      name: "Activity Name",
      mtpd: "MTPD",
      seeAll: "See All",
    },

    bia: {
      title: "BIA Summary",
      departmentStatusTitle: "Division/Business Unit BIA Status",
      noBIA: "No Business Impact Assessment found",
    },
    supplier: {
      title: "Supplier Summary",
      departmentStatusTitle: "Supplier Status",
      noSupplier: "No Supplier found",
    },

    bcp: {
      title: "Business Continuity Plan (BCP)",
      noBCP: "No Business Continuity Plan (BCP) found",
    },
    resiliencePlan: {
      title: "Resilience Plan",
      noData: "No Resilience Plan found",
    },
  },
}
