import { useGlobalStore } from "@/stores/global"
import * as api from "../api/reportTemplateFetcher"

export const useReportTemplate = () => {
  const globalStore = useGlobalStore()

  const getTemplate = async (type, uid) => {
    try {
      return await api.fetchTemplateByType(type, uid)
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  const deleteReportTemplateRevision = async (type, uid) => {
    try {
      return await api.deleteReportTemplateRevision(type, uid)
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  const setReportTemplateDefaultRevision = async (type, uid) => {
    try {
      return await api.setReportTemplateDefaultRevision(type, uid)
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  const getReportTemplateDefaultRevision = async (type) => {
    try {
      return await api.getReportTemplateDefaultRevision(type)
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  const getConfigurableReportTemplateTypes = async () => {
    try {
      return await api.fetchConfigurableTemplateTypes()
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  const getRunnableReportTemplateTypes = async () => {
    try {
      return await api.getRunnableReportTemplateTypes()
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  const fetchReportTemplateTypeDetails = async (type) => {
    try {
      return await api.fetchReportTemplateTypeDetails(type)
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  const fetchReportTemplateRevisions = async (type, perPage = undefined) => {
    try {
      return await api.fetchReportTemplateRevisions(type, perPage ? { per_page: perPage } : {})
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  const uploadReportTemplate = async (type) => {
    try {
      return await api.uploadReportTemplate(type)
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  return {
    getTemplate,
    getConfigurableReportTemplateTypes,
    getRunnableReportTemplateTypes,
    fetchReportTemplateTypeDetails,
    fetchReportTemplateRevisions,
    getReportTemplateDefaultRevision,
    deleteReportTemplateRevision,
    setReportTemplateDefaultRevision,
    uploadReportTemplate,
  }
}
